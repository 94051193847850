import packageJson from '@/../package.json'

import { env } from '@/env'

import { i18n, loadLanguageAsync } from '@/model/i18n'
import { store } from '@/model/store'
import { vueMain } from '@/model/vue'
import router from '@/router'
import { TranslateResult } from 'vue-i18n'
import { Socket } from './socket'

const MONTHS: { [key: string]: string[] } = {
	fr: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
	en: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
}
const MONTHS_SHORT: { [key: string]: string[] } = {
	fr: ['janv.', 'févr.', 'mars', 'avril', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
	en: ['janu.', 'febr.', 'march', 'april', 'may', 'june', 'july', 'augu.', 'sept.', 'oct.', 'nov.', 'dec.'],
}
function ucfirst(str: any) {
	str += ''
	const f = str.charAt(0).toUpperCase()
	return f + str.substr(1)
}

function request<T = any>(method: string, url: string, params?: any) {
	const xhr = new XMLHttpRequest()
	const promise = new Promise<T>((resolve, reject) => {
		xhr.open(method, url)
		xhr.responseType = 'json'
		// if (store.state.connected) {
		// 	xhr.setRequestHeader('Authorization', 'Bearer ' + store.state.token)
		// }
		if (!(params instanceof FormData)) {
			xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
		}
		xhr.onload = (e: any) => {
			if (e.target.status === 200) {
				resolve(e.target.response)
			} else {
				reject(e.target.response)
			}
		}
		xhr.onerror = reject
		xhr.send(params)
	})
	return {
		abort: () => xhr.abort(),
		error: (e: (e: T) => void) => promise.catch(e),
		then: (p: (p: T) => void) => {
			promise.then(p)
			return { error: (e: (e: T) => void) => promise.catch(e) }
		}
	}
}

function post<T = any>(url: any, form: any = {}) {
	if (!(form instanceof FormData)) {
		const f = []
		for (const k in form) { f.push(k + '=' + encodeURIComponent(form[k])) }
		form = f.join('&')
	}
	return request<T>('POST', Funkyz.API + url, form)
}
function put<T = any>(url: any, form: any = {}) {
	if (!(form instanceof FormData)) {
		const f = []
		for (const k in form) { f.push(k + '=' + encodeURIComponent(form[k])) }
		form = f.join('&')
	}
	return request<T>('PUT', Funkyz.API + url, form)
}
function del<T = any>(url: any, form: any = {}) {
	if (!(form instanceof FormData)) {
		const f = []
		for (const k in form) { f.push(k + '=' + encodeURIComponent(form[k])) }
		form = f.join('&')
	}
	return request<T>('DELETE', Funkyz.API + url, form)
}
function get<T = any>(url: any) {
	return request<T>('GET', Funkyz.API + url)
}

class Language {
	public code!: string
	public name!: string
	public flag!: string
}

const DEV = window.location.port === '8080'
const LOCAL = window.location.port === '5000'

const Funkyz = {
	version: packageJson.version,
	normal_version: packageJson.version.replace(/\.\d+$/, ''),
	smart_version: packageJson.version.replace(/\.0$/, ''),
	DEV,
	LOCAL,
	API: 'https://funkyz.net/api/',
	sourceFolder: "https://streaming.funkyz.net/source/",
	streamerURL: "rtmp://streaming.funkyz.net/live/",
	socketURL: "wss://streaming.funkyz.net/ws",
	AVATAR: '',
	STATIC: '/',
	post,
	get,
	put,
	delete: del,
	cgu_version: 1,
	mobile: false,
	socialCollapsed: false,
	menuCollapsed: false,
	menuExpanded: false,
	splitBack: false,
	actions: [],
	dark: 0,
	title: '',
	subtitle: '',
	titleCounter: 0,
	titleTag: null,
	notifsResults: localStorage.getItem('options/notifs-results') === 'true',
	rankingActive: localStorage.getItem('options/ranking-active') === 'true',
	service_worker: null as ServiceWorkerRegistration | null,
	languages: Object.freeze({
		fr: { code: 'fr', name: 'Français', flag: '/image/flag/fr.png' } as Language,
		en: { code: 'en', name: 'English', flag: '/image/flag/gb.png' } as Language,
	} as { [key: string]: Language }),
	timeDelta: 0, // (Date.now() / 1000 | 0) - __SERVER_TIME,
	time: (Date.now() / 1000) | 0,
	timeSeconds: (Date.now() / 1000) | 0,
	large: false,
	flex: false,
	header: true,
	footer: true,
	box: false,
	nativeEmojis: detectNativeEmojis(),
	site: {
		name: 'streaming',
		background: 0,
		top_icon: 0,
		bottom_icon: 0,
		website: '',
		youtube: '',
		facebook: '',
		demo_video: '',
		website_button: '',
		avantages: '',
	},
	setLocale(locale: string) {
		loadLanguageAsync(vueMain, locale)
		Funkyz.post('farmer/set-language', {language: locale})
	},
	objectSize(obj: object): number {
		let size = 0, key
		for (key in obj) { if (obj.hasOwnProperty(key)) { size++ } }
		return size
	},
	first<T extends object>(obj: T) {
		for (const e in obj) {
			if (obj.hasOwnProperty(e)) {
				return obj[e]
			}
		}
		return null
	},
	firstKey(obj: any) {
		for (const e in obj) {
			if (obj.hasOwnProperty(e)) {
				return e
			}
		}
	},
	isEmptyObj(obj: any) {
		for (const e in obj) {
			if (obj.hasOwnProperty(e)) {
				return false
			}
		}
		return true
	},
	selectWhere(array: any, key: string, value: any) {
		for (const e of array) {
			if (e && e[key] === value) { return e }
		}
		return null
	},
	removeOneWhere(array: any, attr: string, condition: any) {
		for (let i = 0; i < array.length; ++i) {
			if (array[i] && array[i][attr] === condition) {
				array.splice(i, 1)
				return
			}
		}
	},
	clone(obj: any) {
		return JSON.parse(JSON.stringify(obj))
	},
	selectText(element: any) {
		Funkyz.removeTextSelections()
		if (window.getSelection) {
			const range = document.createRange()
			range.selectNode(element)
			window.getSelection()!.addRange(range)
		}
	},
	removeTextSelections() {
		if (window.getSelection) {
			window.getSelection()!.removeAllRanges()
		}
	},
	colorToHex(color: number) {
		return "#" + ((1 << 24) + color).toString(16).slice(1)
	},
	rgbArrayToHex(rgb: number[]) {
		return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)
	},
	rgbToHex(r: number, g: number, b: number) {
		return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
	},
	hexToRgb(hex: string): number[] {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
		if (!result) { return [0, 0, 0] }
		return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
	},
	protect(string: any) {
		return ('' + string).replace(/&/g, "&amp;")
			.replace(/>/g, "&gt;").replace(/</g, "&lt;")
			.replace(/"/g, "&quot;").replace(/'/g, "&#39;")
	},
	formatNumber(n: number) {
		return ("" + n).replace(/\B(?=(\d{3})+(?!\d))/g, " ")
	},
	numberPrecision(number: number, precision: number) {
		return number.toPrecision(precision)
	},
	isMobile() {
		return /Mobi/i.test(window.navigator.userAgent)
		/*
		// console.log(window.innerWidth, window.innerHeight, window.screen.orientation)
		const type = (screen.orientation || {}).type || (screen as any).mozOrientation || (screen as any).msOrientation;
		const angle = (screen.orientation || {}).type || (screen as any).mozOrientation || (screen as any).msOrientation;

		let width = window.innerWidth
		if (orientation.angle === 90 && (orientation === "landscape-primary" || orientation === "landscape-secondary")) {
			width = window.innerHeight
		}
		return width < 900
		*/
	},
	contenteditable_paste_protect(element: HTMLElement) {
		// Paste : keep the pure text of the element
		element.addEventListener('paste', (e: any) => {
			e.preventDefault()
			const text = (e.originalEvent || e).clipboardData.getData('text/plain')
			document.execCommand('insertText', false, text)
		})
		// Drop : take the string data in the event and append it to the element
		element.addEventListener('drop', (e: any) => {
			e.preventDefault()
			e.originalEvent.dataTransfer.items[0].getAsString((str: any) => {
				element.textContent = element.innerText + str
			})
		})
	},
	toggleMenu() {
		if (Funkyz.menuExpanded) {
			Funkyz.menuExpanded = false
			Funkyz.dark = 0
		} else if (Funkyz.splitBack) {
			// if ('back' in LW.pages[LW.currentPage]) LW.pages[LW.currentPage].back()
			Funkyz.splitShowList()
		} else {
			Funkyz.menuExpanded = true
			Funkyz.dark = 0.6
		}
	},
	closeMenu() {
		if (Funkyz.menuExpanded) {
			Funkyz.menuExpanded = false
			Funkyz.dark = 0
		}
	},
	splitShowList() {
		if (!Funkyz.isMobile()) { return }
		Funkyz.splitBack = false
	},
	splitShowContent() {
		if (!Funkyz.isMobile()) { return }
		Funkyz.splitBack = true
	},
	setActions(actions: any) {
		Funkyz.actions = actions
	},
	getAvatar(farmerID: number, avatarChanged: number) {
		return avatarChanged === 0 ? '/image/no_avatar.png' : Funkyz.AVATAR + 'avatar/' + farmerID + '.png'
	},
	uploadCheck(file: File) {
		if (!file) {
			return false
		}
		const types = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/bmp']
		if (types.indexOf(file.type) === -1) {
			return false
		}
		// if (file.size > 10485760) {
		// 	return false
		// }
		return true
	},
	fileToImage(file: File, imageElem: Element) {
		const reader = new FileReader()
		reader.onloadend = (e: any) => {
			imageElem.setAttribute('src', e.target.result)
		}
		reader.readAsDataURL(file)
	},
	popupWindow(url: string, title: string, w: number, h: number) {
		const dualScreenLeft = window.screenLeft
		const dualScreenTop = window.screenTop
		const width = window.innerWidth ? window.innerWidth : document.documentElement!.clientWidth ? document.documentElement!.clientWidth : screen.width
		const height = window.innerHeight ? window.innerHeight : document.documentElement!.clientHeight ? document.documentElement!.clientHeight : screen.height
		const left = ((width / 2) - (w / 2)) + dualScreenLeft
		const top = ((height / 2) - (h / 2)) + dualScreenTop
		const newWindow = window.open(url, title, 'scrollbars=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)
		// Puts focus on the newWindow
		if (newWindow) {
			newWindow.focus()
		}
	},
	fullscreen: false,
	fullscreenEnter(element: HTMLElement, callback: (f: boolean) => void) {
		const fullscreenCallback = () => {
			Funkyz.fullscreen = !Funkyz.fullscreen
			vueMain.$emit('resize')
			callback(Funkyz.fullscreen)
		}
		if (element.requestFullscreen) {
			document.onfullscreenchange = fullscreenCallback
			element.requestFullscreen()
		} else if (element.webkitRequestFullscreen) {
			document.onwebkitfullscreenchange = fullscreenCallback
			element.webkitRequestFullscreen()
		} else if (element.mozRequestFullScreen) {
			document.onmozfullscreenchange = fullscreenCallback
			element.mozRequestFullScreen()
		} else if (element.msRequestFullscreen) {
			document.MSFullscreenChange = fullscreenCallback
			element.msRequestFullscreen()
		}
	},
	fullscreenExit() {
		if (document.exitFullscreen) {
			document.exitFullscreen()
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen()
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen()
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen()
		}
	},
	toast(message: string | TranslateResult, durationOrCallback: number | Function = 1800) {
		const d = typeof(durationOrCallback) === "number" ? durationOrCallback : 1800
		const callback = typeof(durationOrCallback) === "function" ? durationOrCallback : null

		const toast = document.createElement("div")
		toast.innerHTML = "<div class='toast'>" + Funkyz.protect(message) + "</div>"
		toast.classList.add("toast-wrapper")
		const toasts = document.querySelector('#app .toasts')
		if (toasts) { toasts.appendChild(toast) }

		setTimeout(() => {
			toast.classList.add('visible')
			setTimeout(() => {
				toast.classList.remove('visible')
				setTimeout(() => {
					toast.remove()
					if (callback != null) { callback() }
				}, 600)
			}, d + 600)
		}, 100)
	},
	get_cursor_position, set_cursor_position,
	formatDate, formatDateTime, formatDuration, formatTime, formatTimeSeconds, formatDayMonthShort,
	setTitle, setSubTitle, setTitleCounter, setTitleTag,
	shadeColor,
	linkify, toChatLink,
	socket: new Socket(),
	message: null as string | null,
	messagePopup: false,
	displayMessage: (message: any) => {
		if (message) {
			// console.log("Display message", message)
			Funkyz.message = message
			Funkyz.messagePopup = true
		}
	},
	userInteraction: false,
	streamCounter: 0
}

function setTitle(title: string | TranslateResult | null, subtitle: string | TranslateResult | null = null) {
	Funkyz.title = title as string
	updateTitle()
	setSubTitle(subtitle)
}
function setTitleCounter(counter: number) {
	Funkyz.titleCounter = counter
	updateTitle()
}
function setTitleTag(tag: any) {
	Funkyz.titleTag = tag
	updateTitle()
}
function setSubTitle(subtitle: any) {
	Funkyz.subtitle = subtitle
}
function updateTitle() {
	let title = Funkyz.title
	if (!title) {
		title = 'Funkyz TV'
	} else {
		title = title + ' - Funkyz TV'
	}
	if (Funkyz.titleCounter > 0) {
		title = '(' + Funkyz.titleCounter + ') ' + title
	}
	if (Funkyz.titleTag !== null) {
		title = '[' + Funkyz.titleTag + '] ' + title
	}
	document.title = title
}

function formatDuration(timestamp: number, capital: boolean = false) {

	if (timestamp === 0 || timestamp == null) { return "-" }

	const seconds = Funkyz.time - timestamp

	let text: any = ""

	if (seconds < 60) { // en dessous d'une minute
		text = i18n.t("main.time_just_now")
	} else if (seconds < 3600) { // en dessous d'une heure
		const minuts = Math.floor(seconds / 60)
		if (minuts === 1) {
			text = i18n.t("main.time_1_minute_ago")
		} else {
			text = i18n.t("main.time_x_minutes_ago", [minuts])
		}
	} else if (seconds < 24 * 3600) { // en dessous d'un jour
		const hours = Math.floor(seconds / 3600)
		if (hours === 1) {
			text = i18n.t("main.time_1_hour_ago")
		} else {
			text = i18n.t("main.time_x_hours_ago", [hours])
		}
	} else if (seconds < 30 * 24 * 3600) { // en dessous d'un mois
		const days = Math.floor(seconds / (24 * 3600))
		if (days === 1) {
			text = i18n.t("main.time_1_day_ago")
		} else {
			text = i18n.t("main.time_x_days_ago", [days])
		}
	} else if (seconds < 12 * 30 * 24 * 3600) { // en dessous d'un an
		const months = Math.floor(seconds / (30 * 24 * 3600))
		if (months === 1) {
			text = i18n.t("main.time_1_month_ago")
		} else {
			text = i18n.t("main.time_x_months_ago", [months])
		}
	} else { // au dessus d'un an
		const years = Math.floor(seconds / (12 * 30 * 24 * 3600))
		if (years === 1) {
			text = i18n.t("main.time_1_year_ago")
		} else {
			text = i18n.t("main.time_x_years_ago", [years])
		}
	}
	if (capital === true) {
		text = ucfirst(text)
	}
	return text
}

function formatDate(timestamp: number) {
	const date = new Date(timestamp * 1000)
	const day = date.getDate()
	const month = date.getMonth()
	const year = date.getFullYear()
	if (i18n.locale === 'fr') {
		return day + ' ' + MONTHS[i18n.locale][month] + ' ' + year
	} else {
		return ucfirst(MONTHS[i18n.locale][month]) + ' ' + day + ', ' + year
	}
}

function formatDayMonthShort(timestamp: number) {
	const date = new Date(timestamp * 1000)
	const day = date.getDate()
	const month = date.getMonth()
	return day + ' ' + MONTHS_SHORT[i18n.locale][month]
}

function formatDateTime(timestamp: number) {
	const date = new Date(timestamp * 1000)
	const day = date.getDate()
	const month = date.getMonth()
	const year = date.getFullYear()
	const hour = date.getHours()
	let minuts: any = date.getMinutes()
	if (minuts < 10) { minuts = '0' + minuts }
	if (i18n.locale === 'fr') {
		return day + ' ' + MONTHS[i18n.locale][month] + ' ' + year + " à " + hour + ":" + minuts
	} else /* if (i18n.locale == 'en') */ {
		return ucfirst(MONTHS[i18n.locale][month]) + ' ' + day + ', ' + year + " at " + hour + ":" + minuts
	}
}

function formatTimeSeconds(time: number) {
	const hours = Math.floor(time / 3600)
	const minuts = Math.floor((time % 3600) / 60)
	const seconds = time - hours * 3600 - minuts * 60
	let res = ""
	if (hours > 0) { res += hours + "h " }
	if (minuts > 0) { res += ("0" + minuts + "m ").substr(-4) }
	if (seconds !== 0) { res += ("0" + seconds + "s").substr(-3) }
	return res
}

function formatTime(time: number) {
	const date = new Date(time * 1000)
	const minuts = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
	return date.getHours() + ":" + minuts
}

function escapeRegExp(str: string) {
	return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
}

function get_cursor_position(editableDiv: any) {
	if (window.getSelection) {
		const sel = window.getSelection()
		if (sel && sel.rangeCount) {
			const range = sel.getRangeAt(0)
			if (range.commonAncestorContainer.parentNode === editableDiv) {
				return range.endOffset
			}
		}
	}
	return 0
}

function set_cursor_position(el: any, pos: number) {
	const range = document.createRange()
	const sel = window.getSelection()
	range.setStart(el.firstChild, pos)
	range.collapse(true)
	if (sel) {
		sel.removeAllRanges()
		sel.addRange(range)
	}
}

function toChatLink(url: string, text: string, blank: string) {
	blank = blank ? blank : ""
	return '<a ' + blank + ' href="' + url + '">' + text + '</a>'
}

function linkify(html: string) {
	const make_blank = (url: string) => {
		return (url.indexOf("http://www.funkyz.com") !== 0
			&& url.indexOf("http://funkyz.com") !== 0
			&& url.indexOf("https://funkyz.com") !== 0
			&& url.indexOf("https://www.funkyz.com") !== 0) ? "target='_blank' rel='noopener'" : ""
	}
	const email_pattern = /\w+@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6})+/gim
	const url_regex = /((?:https?):\/\/[\w-]+\.[\w-]+(?:\.\w+)*)|((?:www\.)?funkyz\.com)/gim
	let match

	while (match = url_regex.exec(html)) {
		let i = match.index + match[0].length
		let par = 0, curly = 0, square = 0
		if (html[i] === '/') {
			while (i < html.length) {
				const c = html[i]
				if (c === ' ' || c === '\n') { break }
				if (c === '(') { par++ }
				if (c === '[') { square++ }
				if (c === '{') { curly++ }
				if (c === ')' && --par < 0) { break }
				if (c === ']' && --square < 0) { break }
				if (c === '}' && --curly < 0) { break }
				i++
			}
			let last = html[i - 1]
			while (/[\.,!?:]/.test(last)) {
				last = html[--i - 1]
			}
		}
		const url = html.substring(match.index, i).replace(/\$/g, '%24')
		const real_url = (url.indexOf('http') === -1) ? 'http://' + url : url
		const blank = make_blank(real_url)

		html = html.substring(0, match.index) + toChatLink(real_url, url, blank) + html.substring(i)
		url_regex.lastIndex += real_url.length + blank.length + '<a href=""  ></a>'.length
	}
	return html.replace(email_pattern, '<a target="_blank" rel="noopener" href="mailto:$&">$&</a>')
}

function detectNativeEmojis() {
	const ctx = document.createElement("canvas").getContext("2d")
	if (ctx) {
		ctx.fillText("😗", -2, 4)
		return ctx.getImageData(0, 0, 1, 1).data[0] > 0
	}
	return false
}

function shadeColor(color: string, amount: number) {
	return '#' + color.replace(/^#/, '').replace(/../g, c => ('0' + Math.min(255, Math.max(0, parseInt(c, 16) + amount)).toString(16)).substr(-2))
}

export { Funkyz, Language }
