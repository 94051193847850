import App from '@/component/app/app.vue'
import Panel from '@/component/app/panel.vue'
import Avatar from '@/component/avatar.vue'
import ChatElement from '@/component/chat/chat.vue'
import Popup from '@/component/popup.vue'
import { env } from '@/env'
import { Funkyz } from '@/model/funkyz'
import { i18n } from '@/model/i18n'
import { store } from "@/model/store"
import router from '@/router'
import Vue from 'vue'

import Vuetify from 'vuetify/lib'
Vue.use(Vuetify)

import tooltip from '@/vtooltip-fast'
Vue.component('tooltip', tooltip)

import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition'
import '../fade-transition.sass'
const myTransition = createSimpleTransition('my-transition')
Vue.component('my-transition', myTransition)

import '@videojs/http-streaming'
import 'video.js/dist/video-js.css'

import contenteditable from 'vue-contenteditable'
Vue.use(contenteditable)

Vue.config.productionTip = false

Vue.mixin({
	data() {
		return { Funkyz }
	},
	created() {
		this.env = env
	}
})

Vue.filter('number', Funkyz.formatNumber)
Vue.filter('date', Funkyz.formatDate)
Vue.filter('datetime', Funkyz.formatDateTime)
Vue.filter('duration', Funkyz.formatDuration)

Vue.component('avatar', Avatar)
Vue.component('chat', ChatElement)
Vue.component('error', Error)
Vue.component('popup', Popup)
Vue.component('panel', Panel)

Vue.directive('autostopscroll', {
	inserted: (el, binding) => {
		const top = binding.value === 'top' || !binding.value
		const bottom = binding.value === 'bottom' || !binding.value
		el.addEventListener("wheel", (e: WheelEvent) => {
			if ((top && e.deltaY < 0 && el.scrollTop === 0) || (bottom && e.deltaY > 0 && Math.abs(el.scrollTop - (el.scrollHeight - el.offsetHeight)) < 1)) {
				e.preventDefault()
			}
		})
	}
})

const vueMain = new Vue({
	router, i18n, store,
	data: { savedPosition: 0 },
	vuetify: new Vuetify(),
	methods: {
		onLanguageLoaded: () => {
			if (!Funkyz.DEV) {
				const style = "color: black; font-size: 13px; font-weight: bold;"
				const styleRed = "color: red; font-size: 14px; font-weight: bold;"
				console.log("%c" + i18n.t('main.console_alert_1'), style)
				console.log("%c" + i18n.t('main.console_alert_2'), styleRed)
				console.log("%c" + i18n.t('main.console_alert_3'), style)
				console.log("")
				console.log("%c✔️ " + i18n.t('main.console_github'), style)
				console.log("")
			}
		}
	},
	render: (h) => {
		return h(App)
	},
	created() {
		window.addEventListener('keydown', (event) => {
			this.$emit('keydown', event)
			if (event.ctrlKey && event.keyCode === 83) {
				this.$emit('ctrlS')
				event.preventDefault()
			} else if (event.ctrlKey && event.keyCode === 81) {
				this.$emit('ctrlQ')
			} else if (event.ctrlKey && event.keyCode === 70 && !event.shiftKey) {
				this.$emit('ctrlF', event)
			} else if (event.keyCode === 27) {
				this.$emit('escape')
			} else if (event.altKey && event.which === 37) {
				this.$emit('previous', event)
			} else if (event.altKey && event.which === 39) {
				this.$emit('next', event)
			} else if (event.ctrlKey && event.keyCode === 80) {
				this.$emit('ctrlP', event)
			}
		})
		window.addEventListener('keyup', (event) => {
			this.$emit('keyup', event)
		})
		Funkyz.mobile = Funkyz.isMobile()
		window.addEventListener('resize', () => {
			this.$emit('resize')
			Funkyz.mobile = Funkyz.isMobile()
		})
		window.addEventListener('focus', () => {
			this.$emit('focus')
		})
		window.addEventListener('click', () => {
			this.$emit('htmlclick')
		})
		setInterval(() => {
			Funkyz.timeSeconds = (Date.now() / 1000) | 0 - Funkyz.timeDelta
		}, 1000)
		setInterval(() => {
			Funkyz.time = (Date.now() / 1000) | 0 - Funkyz.timeDelta
		}, 1000 * 60)

		this.$on('loaded', () => {
			Vue.nextTick(() => {
				// console.log("loaded", this.$data.savedPosition)
				if (this.$data.savedPosition > 0) {
					window.scrollTo(0, this.$data.savedPosition)
					setTimeout(() => {
						window.scrollTo(0, this.$data.savedPosition)
						this.$data.savedPosition = 0
					}, 100)
				}
			})
		})
		Funkyz.socket.connect()
		window.onbeforeunload = () => {
			const component = router.currentRoute.matched[0].instances.default
			const beforeRouteLeave = (component.$options as any).beforeRouteLeave
			if (beforeRouteLeave) {
				if (!beforeRouteLeave[0].bind(component)()) { return "Confirm" }
			}
		}

		// Message ?
		// Funkyz.get('farmer/get-message').then(data => {
		// 	Funkyz.displayMessage(data.message)
		// })
	}
}).$mount('#app')

export { vueMain }