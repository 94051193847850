

	import { Component, Vue } from 'vue-property-decorator'
	import { Funkyz } from '../../model/funkyz'

	@Component({
		components: {}
	})
	export default class App extends Vue {

		created() {
			const match = document.location.host.match('(.*).tv.funkyz.net')
			Funkyz.site.name = match ? match[1] : 'streaming'
			Funkyz.get('site/get/' + Funkyz.site.name).then(site => Funkyz.site = site)

			// Funkyz.get('storage/get/demo_video').then(data => Funkyz.demo_video = data.value)
			// Funkyz.get('storage/get/image-main_bg.jpg').then(data => Funkyz.main_bg = data.value)
			// Funkyz.get('storage/get/image-top_icon.png').then(data => Funkyz.top_icon = data.value)
			// Funkyz.get('storage/get/image-bottom_icon.png').then(data => Funkyz.bottom_icon = data.value)
			// Funkyz.get('storage/get/main_website').then(data => Funkyz.main_website = data.value)
			// Funkyz.get('storage/get/main_youtube').then(data => Funkyz.main_youtube = data.value)
			// Funkyz.get('storage/get/main_facebook').then(data => Funkyz.main_facebook = data.value)
			// Funkyz.get('storage/get/website_button').then(data => Funkyz.website_button = data.value)
			// Funkyz.get('storage/get/avantages').then(data => Funkyz.avantages = data.value)
		}
		userInteraction() {
			Funkyz.userInteraction = true
		}
	}
