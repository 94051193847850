import { Funkyz } from '@/model/funkyz'
import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import { Chat, ChatType } from './chat'
import { User } from './user'
import { vueMain } from './vue'

class AppState {
	public token: string | null = null
	public connected: boolean = false
	public admin: boolean = false
	public user: User | null = null
	public chat: {[key: string]: Chat} = {}
	public wsconnected: boolean = false
	public wsdisconnected: boolean = false
	public supertoken: string = ''
	public unreadMessages: number = 0
	public unreadNotifications: number = 0
	public notifications: Notification[] = []
	public last_ping: number = 0
}

Vue.use(Vuex)
const store: Store<AppState> = new Vuex.Store({
	state: new AppState(),
	getters: {
		admin: (state: AppState) => state.user && state.user.admin,
	},
	mutations: {
		"connected"(state: AppState, data: {token: string, admin: boolean}) {
			state.connected = true
			state.token = data.token
			state.admin = data.admin
		},
		"connect"(state: AppState, data: {user: User, token: string}) {
			state.user = data.user
			state.token = data.token
			state.connected = true
			state.admin = !!data.user.admin
			localStorage.setItem('connected', 'true')
			localStorage.removeItem('login-attempt')
			localStorage.setItem('token', data.token)
			localStorage.setItem('admin', '' + !!data.user.admin)
			vueMain.$emit('connected', state.user)
		},
		"disconnect"(state: AppState) {
			Funkyz.post('farmer/disconnect')
			state.connected = false
			localStorage.removeItem('connected')
			localStorage.removeItem('admin')
			localStorage.removeItem('login-attempt')
			localStorage.removeItem('token')
			localStorage.removeItem('editor/tabs')
			state.token = null
			state.user = null
			Funkyz.socket.disconnect()
			Funkyz.setTitleCounter(0)
			state.notifications = []
			state.unreadMessages = 0
			state.unreadNotifications = 0
			state.chat = {}
			console.clear()
		},
		"wsconnected"(state: AppState) {
			state.wsconnected = true
			state.wsdisconnected = false
			vueMain.$emit('wsconnected')
		},
		"wsclose"(state: AppState) {
			for (const chat in state.chat) {
				state.chat[chat].invalidated = true
			}
			state.wsconnected = false
			state.wsdisconnected = true
		},
		'clear-chat'(state: AppState, chat: string) {
			Vue.delete(state.chat, chat)
		},
		'init-team-chat'(state: AppState) {
			if (!state.chat.team) {
				Vue.set(state.chat, 'team', new Chat('team', ChatType.TEAM))
			}
		},
		'chat-receive'(state: AppState, data: any) {
			const channel = data.message[0]
			if (!state.chat[channel]) {
				Vue.set(state.chat, channel, new Chat(channel, ChatType.GLOBAL))
			}
			state.chat[channel].add(data.message[1], data.message[2], data.message[6], data.message[5], data.message[3], data.message[4])
			vueMain.$emit('chat', [channel])
		},
		'chat-receive-pack'(state: AppState, pack: any) {
			if (!pack.length) { return }
			const channel = pack[0][0]
			if (!state.chat[channel]) {
				Vue.set(state.chat, channel, new Chat(channel, ChatType.GLOBAL))
			}
			state.chat[channel].set_messages(pack)
			vueMain.$emit('chat', [channel])
		},
	},
})
export { store }
