
	import { Chat, ChatMessage } from '@/model/chat'
	import { Funkyz } from '@/model/funkyz'
	import { SocketMessage } from '@/model/socket'
	import { store } from '@/model/store'
	import { User } from '@/model/user'
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
	import ChatInput from './chat-input.vue'

	@Component({
		components: { 'chat-input': ChatInput }
	})
	export default class ChatElement extends Vue {
		@Prop() channel!: string
		muteDialog: boolean = false
		unmuteDialog: boolean = false
		reportDialog: boolean = false
		reportContent: string = ''
		userScroll: boolean = false
		unread: boolean = false
		get loading() {
			return !!this.channel && !store.state.chat[this.channel]
		}
		get chat() {
			return this.channel ? store.state.chat[this.channel] : null
		}
		get privateMessages() {
			return this.channel && this.channel.startsWith('pm-')
		}

		created() {
			this.$root.$on('chat', this.newMessage)
			this.$root.$on('resize', this.updateScroll)
			this.$root.$on('wsconnected', this.update)
		}

		beforeDestroy() {
			this.$root.$off('chat', this.newMessage)
			this.$root.$off('resize', this.updateScroll)
			this.$root.$off('wsconnected', this.update)
		}

		newMessage(e: any) {
			if (e[0] === this.channel) {
				this.updateScroll()
				if (!this.scrollBottom()) { this.unread = true }

				// On reçoit un message sur un chat de conversation privée, il est lu tout de suite
				this.read()
			}
		}

		scrollBottom() {
			const messages = this.$refs.messages as HTMLElement
			if (!messages) { return true }
			return messages && Math.abs((messages.scrollTop + messages.offsetHeight) - messages.scrollHeight) < 3
		}

		mounted() {
			this.updateScroll()
		}

		scroll() {
			if (this.scrollBottom()) {
				this.userScroll = false
				this.unread = false
			} else {
				this.userScroll = true
			}
		}

		updated() {
			this.updateScroll()
		}

		updateScroll(force: boolean = false) {
			if (!this.userScroll || force) {
				const messages = this.$refs.messages as HTMLElement
				setTimeout(() => {
					if (messages) {
						messages.scrollTop = messages.scrollHeight + 1000
						this.unread = false
					}
				}, 60)
			}
		}

		@Watch('channel', {immediate: true})
		update() {
			if (!this.channel) { return }
			if (this.channel === 'team') {
				if (!this.$store.state.chat.team) {
					this.$store.commit('init-team-chat')
					// Funkyz.socket.send([SocketMessage.TEAM_CHAT_ENABLE])
				}
			} else if (this.channel.startsWith('pm-')) {
				const id = parseInt(this.channel.replace('pm-', ''), 10)
				if (!this.$store.state.chat['pm-' + id] || this.$store.state.chat['pm-' + id].messages.length < 50) {
					if (id === 0) { return }
					Funkyz.get('message/get-messages/' + id + '/' + 50 + '/' + 1).then(data => {
						this.$store.commit('clear-chat', 'pm-' + id)
						for (const message of data.messages.reverse()) {
							this.$store.commit('pm-receive', {message: [id, message.farmer_id, message.farmer_name, message.content, false, message.farmer_color, message.avatar_changed, message.date]})
						}
						for (const farmer of data.farmers) {
							this.$store.commit('add-conversation-participant', {id, farmer})
						}
					})
				}
				this.read()
			} else {
				if (this.channel in this.$store.state.chat && this.$store.state.chat[this.channel].invalidated) {
					this.$store.commit('clear-chat', this.channel)
				}
				// Funkyz.socket.enableChannel(this.channel)
			}
		}

		sendMessage(message: any) {
			if (message.startsWith('/ping')) {
				this.$store.commit('last-ping', Date.now())
			}
			if (this.channel === 'team') {
				// Funkyz.socket.send([SocketMessage.TEAM_CHAT_SEND, message])
			} else if (!this.channel || this.privateMessages) {
				this.$emit('send', message)
			} else {
				// Funkyz.socket.send([SocketMessage.FORUM_CHAT_SEND, this.channel, message])
			}
		}

		read() {
			if (this.chat && this.chat.conversation) {
				// Funkyz.socket.send([SocketMessage.MP_READ, this.chat.conversation.id])
				this.chat.conversation.unread = false
			}
		}
	}
