
	import { env } from '@/env'
	import { Funkyz } from '@/model/funkyz'
	import { User } from '@/model/user'
	import { Component, Prop, Vue } from 'vue-property-decorator'

	@Component({ name: "avatar" })
	export default class Avatar extends Vue {
		@Prop() farmer!: User
		@Prop() on!: any
		get url() {
			if (this.farmer) {
				if (this.farmer.id > 0) {
					if (this.farmer.avatar_changed > 0) {
						return Funkyz.AVATAR + 'avatar/' + this.farmer.id + '.png?' + this.farmer.avatar_changed
					}
				} else {
					return '/image/lw_avatar.png'
				}
			}
			return '/image/no_avatar.png'
		}
	}
