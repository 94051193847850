
	import { Funkyz } from '@/model/funkyz'
	import { i18n } from '@/model/i18n'
	import { Component, Prop, Vue } from 'vue-property-decorator'
	import EmojiPicker from './emoji-picker.vue'

	@Component({ components: { 'emoji-picker': EmojiPicker } })
	export default class ChatInput extends Vue {
		message: string = ''
		emojis: boolean = false
		cursor: number = 0
		commandsEnabled: boolean = false
		commandFilter: string = ''
		commandsX: number = 0
		commandsY: number = 0
		commandsHeight: number = 0

		mounted() {
			Funkyz.contenteditable_paste_protect(this.$refs.input as HTMLElement)
		}
		updateCursor() {
			const input = this.$refs.input as HTMLElement
			this.cursor = Funkyz.get_cursor_position(input)
		}
		keyDown(e: KeyboardEvent) {
			if (e.which === 9) {
				//
			} else if (e.which === 13 && !e.shiftKey) {
				e.preventDefault()
			}
			e.stopPropagation()
		}
		keyUp(e: KeyboardEvent) {
			this.updateCursor()
			const input = this.$refs.input as HTMLElement
			this.message = input.innerText

			if (e.which === 13 && !e.shiftKey) {
				if (this.message.length === 0) {
					return
				}
				if (this.message.length > 1000) {
					Funkyz.toast(i18n.t('main.chat_too_long') as string)
					return
				}
				if (this.message === '/ping') {
					// LW.chat.last_ping = Date.now()
				}
				this.$emit('message', this.message)
				input.textContent = ''
				this.commandsEnabled = false
			}
			e.stopPropagation()
		}
		addEmoji(emoji: string) {
			const input = this.$refs.input as HTMLElement
			let cursor_position = this.cursor
			const text = input.innerText
			input.textContent = text.substring(0, cursor_position) + emoji + text.substring(cursor_position)
			input.focus()
			cursor_position += emoji.length
			Funkyz.set_cursor_position(input, cursor_position)
			this.cursor = cursor_position
		}

	}
