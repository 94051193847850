import { env } from '@/env'
import { Funkyz } from '@/model/funkyz'
import { vueMain } from '@/model/vue'
import { store } from './store'

enum SocketMessage {
	REGISTER_STREAM = 1,
	STREAM_USER_COUNT = 2,
	LEAVE_STREAM = 3,
}

class Socket {
	public socket!: WebSocket
	public queue: any[] = []
	public retry_count: number = 10
	public retry_delay: number = 1000

	public connect() {
		const url = Funkyz.socketURL
		this.socket = new WebSocket(url)

		this.socket.onopen = () => {
			store.commit('wsconnected')
			this.retry_count = 10
			this.retry_delay = 5000
			for (const p of this.queue) {
				this.send(p)
			}
			this.queue = []
		}
		this.socket.onclose = () => {
			store.commit('wsclose')
			this.retry()
		}
		this.socket.onmessage = (msg: any) => {
			const json = JSON.parse(msg.data)
			const id = json[0]
			const data = json[1]
			vueMain.$emit('wsmessage', {type: id, data})

			switch (id) {
				case SocketMessage.STREAM_USER_COUNT: {
					Funkyz.streamCounter = data
					break
				}
			}
		}
	}
	public retry() {
		if (this.retry_count > 0) {
			this.retry_count--
			setTimeout(() => this.connect(), this.retry_delay)
			this.retry_delay += 500
		}
	}

	public send(message: any) {
		if (this.socket && this.socket.readyState === WebSocket.OPEN) {
			this.socket.send(JSON.stringify(message))
		} else {
			this.queue.push(message)
		}
	}
	public disconnect() {
		if (this.socket) { this.socket.close() }
	}
	public connected() {
		return this.socket && this.socket.readyState === WebSocket.OPEN
	}
	public connecting() {
		return this.socket && this.socket.readyState === WebSocket.CONNECTING
	}
}
export { Socket, SocketMessage }
