
	import { Emojis } from '@/model/emojis'
	import { Funkyz } from '@/model/funkyz'
	import { Component, Prop, Vue } from 'vue-property-decorator'

	@Component({})
	export default class EmojiPicker extends Vue {
		width: number = 352
		categories = Emojis.categories
		Emojis = Emojis
	}
