
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
	@Component({ name: "popup" })
	export default class Popup extends Vue {
		@Prop() value!: boolean
		@Prop() title!: string
		@Prop() width!: number
		@Prop() full!: boolean
		@Prop() persistent!: Boolean
		content_created: boolean = false
		created() {
			if (this.value) {
				this.content_created = true // Content created direclty from creation
			}
			this.$watch('value', (new_value, old_value) => {
				if (new_value === true) {
					this.content_created = true
				}
			})
		}
		hasIcon() {
			return !!this.$slots.icon
		}
		close() {
			this.$emit('input', false)
		}
	}
