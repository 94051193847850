import { locale } from '@/locale'

const Streaming = () => import(/* webpackChunkName: "[request]" */ `@/component/streaming/streaming.${locale}.i18n`)
const Admin = () => import(/* webpackChunkName: "[request]" */ `@/component/admin/admin.${locale}.i18n`)

import { store } from '@/model/store'
import { vueMain } from '@/model/vue'
import Vue from 'vue'
import Router, { Route, RouteConfig } from 'vue-router'
import { Funkyz } from './model/funkyz'

const connected = (to: Route, from: Route, next: any) => {
	if (!store.state.connected) {
		next('/')
	} else {
		next()
	}
}
const adminGuard = (to: Route, from: Route, next: any) => {
	if (!store.state.admin) {
		next('/')
	} else {
		next()
	}
}
const disconnected = (to: Route, from: Route, next: any) => {
	if (store.state.connected) {
		next('/')
	} else {
		next()
	}
}

Vue.use(Router)

const routes = [
	{ path: '/', component: Streaming },
	{ path: '/stream/:stream', component: Streaming },
	{ path: '/demo', component: Streaming, props: {demo: true} },
	{ path: '/admin', component: Admin, beforeEnter: adminGuard },
	{ path: '/admin/:site', component: Admin, beforeEnter: adminGuard },
	{ path: '*', component: Error },
] as RouteConfig[]

function scroll_to_hash(hash: string, route: Route) {
	const id = decodeURIComponent(hash).replace(/'/g, '~').substring(1)
	const element = document.getElementById(id)
	// console.log("scroll element", id, element, route.meta)
	if (element) {
		const offset = route.meta!.scrollOffset
		setTimeout(() => {
			window.scrollTo(0, element.getBoundingClientRect().top + window.scrollY - offset)
		})
	}
}

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		// console.log("scrollBehavior", to, from, savedPosition)
		vueMain.$data.savedPosition = 0
		if (to.hash) {
			scroll_to_hash(to.hash, to)
			return null
		}
		if (savedPosition && !from.hash) {
			vueMain.$data.savedPosition = savedPosition.y
		} else if (!to.meta!.noscroll) {
			return { x: 0, y: 0 }
		}
	},
})

router.afterEach((to: Route) => {
	if (to.hash) {
		vueMain.$once('loaded', () => {
			setTimeout(() => {
				scroll_to_hash(to.hash, to)
			}, 100)
		})
	}
})

router.beforeEach((to: Route, from: Route, next: any) => {
	if (!store.state.connected) {
		const token = localStorage.getItem('token')
		const admin = localStorage.getItem('admin') === 'true'
		if (localStorage.getItem('connected') === 'true') {
			store.commit('connected', {token, admin})
			Funkyz.post('customer/get-from-token', {token}).then(data => {
				store.commit('connect', {user: data.customer, token})
			}).error(() => {
				store.commit('disconnect')
				router.push('/')
			})
		}
	}

	next()
})

export default router
