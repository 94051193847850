import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":_vm.width,"left":true,"nudge-top":0,"min-width":_vm.width,"max-width":_vm.width,"top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}],staticClass:"chat-input-emoji"},on),[_c('div',{class:{'emoji-font': !_vm.Funkyz.nativeEmojis}},[_vm._v("😀")])])]}}])},[_c(VTabs,{key:_vm.categories.length,staticClass:"tabs",attrs:{"grow":"","show-arrows":false}},[_c(VTabsSlider,{staticClass:"indicator"}),_vm._l((_vm.categories),function(category,c){return _c(VTab,{key:c,staticClass:"tab",attrs:{"href":'#tab-' + c}},[_c('span',{directives:[{name:"emojis",rawName:"v-emojis"}]},[_vm._v(_vm._s(category.icon))])])}),_vm._l((_vm.categories),function(category,c){return _c(VTabItem,{directives:[{name:"autostopscroll",rawName:"v-autostopscroll"}],key:c,staticClass:"content",attrs:{"value":'tab-' + c}},[_c('div',{staticClass:"grid"},[_vm._l((category.emojis),function(emoji,e){return [(c == 0 && e < 11)?_c('img',{key:e,staticClass:"emoji classic",attrs:{"src":'/image/emoji/' + _vm.Emojis.custom[emoji] + '.png',"title":emoji},on:{"click":function($event){return _vm.$emit('pick', emoji)}}}):_c('div',{key:e,staticClass:"emoji",class:{'emoji-font': !_vm.Funkyz.nativeEmojis},on:{"click":function($event){return _vm.$emit('pick', emoji)}}},[_vm._v(_vm._s(emoji))])]})],2)])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }